import React, { useLayoutEffect, useContext } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import AuthContext from "../../context/auth/auth-context"
import LinkWrapper from "../../lib/link-wrapper"
import Layout from "../../theme/layout"
import SEO from "../header/seo"

const AccountWrapper = ({ children, location, accountStep = "" }) => {
  const { loggedInState } = useContext(AuthContext)

  // Check if user is not logged in and show their email address
  useLayoutEffect(() => {
    if (loggedInState === false) {
      navigate("/login/")
    }
  }, [loggedInState])

  return (
    <Layout location={location}>
      <SEO title="My Account Information" location={location} f />
      <StyledH1>My Account</StyledH1>
      <AccountNavigationPanelStyling>
        <LinkWrapper
          className={`button-class ${
            accountStep == "my-account" ? "button-active" : "inactive"
          }`}
          to="/my-account/"
        >
          My Account
        </LinkWrapper>
        <LinkWrapper
          className={`button-class ${
            accountStep == "billing-details" ? "button-active" : "inactive"
          }`}
          to="/my-account/billing-details/"
        >
          Billing details
        </LinkWrapper>
        <LinkWrapper
          className={`button-class ${
            accountStep == "shipping-details" ? "button-active" : "inactive"
          }`}
          to="/my-account/shipping-details/"
        >
          Shipping Details
        </LinkWrapper>
        <LinkWrapper
          className={`button-class ${
            accountStep == "order-history" ? "button-active" : "inactive"
          }`}
          to="/my-account/order-history/"
        >
          Order History
        </LinkWrapper>
      </AccountNavigationPanelStyling>
      {loggedInState && children ? <div>{children}</div> : null}
    </Layout>
  )
}

export default AccountWrapper

// ===============
//     STYLES
// ===============
const StyledH1 = styled.h1`
  margin: 0;
`
const AccountNavigationPanelStyling = styled.div`
  display: flex;
  padding: 3rem 0;
  justify-content: space-between;
  text-transform: uppercase;
  a {
    margin-bottom: 10px;
    width: 100%;
  }
  a + a {
    margin-left: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    a + a {
      margin-left: 0;
    }
  }
`
