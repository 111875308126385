import React from "react"
import styled from "styled-components"
import { format } from "date-fns"

// ======================
// 	     Helpers
// ======================
const dateStringFormat = (dateString) => {
  if (
    typeof dateString != "undefined" &&
    dateString &&
    dateString.includes(" ")
  ) {
    try {
      let parsedDate = Date.parse(dateString.split(" ")[0])
      return format(new Date(parsedDate), "dd MMM yyyy")
      // eslint-disable-next-line no-empty
    } catch {}
  }
  return dateString
}

// ======================
// 	     COMPONENT
// ======================
const OrderTitle = ({
  orderNumber,
  orderNumberFormatted,
  orderDate,
  payDate,
  paymentMethod,
}) => {
  const orderDateString = dateStringFormat(orderDate)
  const payDateString = dateStringFormat(payDate)

  return (
    <OrderTitleStyling>
      <OrderNumberStyling>
        {"Order "}
        {orderNumberFormatted ? orderNumberFormatted : `#${orderNumber}`}
      </OrderNumberStyling>
      {!orderDate ? null : (
        <DateStyling>
          {"Ordered "}
          {orderDateString}
        </DateStyling>
      )}
      {!payDate ? null : <DateStyling>Paid {payDateString}</DateStyling>}
    </OrderTitleStyling>
  )
}

export default OrderTitle

// ======================
// 	      STYLES
// ======================
const OrderTitleStyling = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.7rem;
  margin: 20px 0;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 1.1rem;
    text-align: center;
  }
`
const OrderNumberStyling = styled.div`
  font-weight: bold;
  margin-right: 10px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 1.3rem;
    margin-right: 5px;
  }
`

const DateStyling = styled.div`
  display: flex;
  flex-direction: row;
  :last-child {
    margin-left: 10px;
  }
  :not(:last-child) {
    margin: 0 10px;
  }
  :before {
    display: block;
    content: "";
    border: 2px solid ${({ theme }) => theme.colors.blue};
    margin-right: 20px;
  }
`
