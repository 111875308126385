import React from "react"
import styled from "styled-components"

import AccountWrapper from "../../components/account/account-wrapper"
import OrdersList from "../../components/account/orders/orders-list"

const OrderHistory = ({ location }) => {
  return (
    <AccountWrapper location={location} accountStep="order-history">
      <StyledH2>Order History</StyledH2>
      <OrdersList />
    </AccountWrapper>
  )
}

export default OrderHistory

// ===============
//     STYLES
// ===============
const StyledH2 = styled.h2`
  padding-bottom: 20px;
`
