import React from "react"
import styled from "styled-components"

const CrossShape = ({ id = "cross", className }) => {
  return <CrossShapeSpanStyling id={id} className={className} />
}

export default CrossShape

// ===============
//     STYLES
// ===============
const CrossShapeSpanStyling = styled.span`
  opacity: 0;
  transition: 0.2s all ease-in-out;
  &#cross {
    margin-left: auto;
    margin-right: 4px;
    display: inline-block;
    background: white;
    height: 10px;
    position: relative;
    width: 2px;
    transform: rotate(45deg);
  }
  &#cross:after {
    background: white;
    content: "";
    height: 2px;
    left: -4px;
    position: absolute;
    top: 4px;
    width: 10px;
  }
  &#cross.filter-closed {
    transform: rotate(180deg);
    transition: 0.2s all ease-in-out;
    opacity: 1;
  }
  &#cross.cross-open {
    transform: rotate(45deg);
    transition: 0.2s all ease-in-out;
    opacity: 1;
  }
  &#cross:hover {
    opacity: 1;
  }
  &#cross.blue:after {
    background: white;
  }
  &#cross.blue {
    background: white;
  }
`
